import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
const QuiSom = () => {
  return (
    <div>
      <section className="banner-one" style={{ backgroundImage: `url("http://fincat.es/maqueta/assets/images/main-slider/slider-1-2.jpg")`,width:'100%',height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',   backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', opacity: 0.8,}}>
        <div >
        </div>
        <div className="container">
          <div className="content-box">
            <div className="top-title">
              <h2 style={{ color: 'white', textShadow: 'black 0.1em 0.1em 0.2em' }}>Finques Catalunya</h2>
            </div>
            <h5 className="" style={{ color: 'white', textShadow: 'black 0.1em 0.1em 0.2em' }}>Des de fa 50 anys a Finques Catalunya treballem per satisfer les necessitats dels nostres clients i garantir d’aquesta manera l’eficàcia en la gestió immobiliària. Aquesta experiència ens permet oferir una solució integral i personalitzada a més de convertir-nos en l’operador líder del sector en la comarca del Gironès.
              <br /><br />
              El tracte humà, el dinamisme i l’accessibilitat juntament amb el compromís fan de Finques Catalunya una empresa sensible a les necessitats de cada persona en el moment de plantejar-se i decidir sobre noves opcions de futur.
              <br /><br />
              Comptem amb la professionalitat d’un equip capaç d’assessorar amb serietat i absoluta confiança a cada client i, per aquesta raó, Finques Catalunya té la seu central al cor de Girona.
              <br /><br />
              Finques Catalunya és una immobiliaria especialitzada en la compra i venda, lloguer i/o traspàs de pisos, cases, locals, naus, aparcaments (pàrquings tancats o de ratlles amb o sense trasters), terrenys i solars a la ciutat de Girona i rodalies. Finques Catalunya li ofereix immobles a la comarca del gironès i en especial a la ciutat de Girona, Salt, Sarrià de Ter, Fornells de la Selva, Quart, Sant Gregori, Bescanó, Cervià de Ter i altres poblacions properes.
              <br /><br />
              En definitiva, oferim la màxima garantia per que vostè pugui gaudir del millor servei immobiliari.</h5>
            <div className="banner_one_bottom_icon_text">
              <div className="banner_one_bottom_icon">
                <span className="icon-building"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuiSom;