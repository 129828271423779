import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PisoCard from './PisoCard';

const PisDestecat = () => {
  const [pisos, setPisos] = useState([]);

  useEffect(() => {
    fetch('http://fincat.es/api/pisos_destacats.php')
      .then((response) => response.json())
      .then((data) => {
        setPisos(data);
      })
      .catch((error) => {
        console.error('Error al obtener los pisos:', error);
      });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <div>
      <Slider {...sliderSettings}>
        {pisos.map((piso) => (
          <div key={piso.id}>
            <PisoCard piso={piso} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PisDestecat;
