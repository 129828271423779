import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Modal from 'react-modal';
import './DetallePiso.css'; 
import ContactePis from './contactePis';


const DetallePiso = () => {
  const { id } = useParams();
  const { referencia } = useParams();

  const [piso, setPiso] = useState(null);
  const [images, setImagenes] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchPisoAndImages = async () => {
      try {
        const [detallesResponse, galeriaResponse] = await Promise.all([
          fetch(`http://fincat.es/api/ficha_pis.php?id=${referencia}`),
          fetch(`http://fincat.es/api/galeria_ficha.php?id=${referencia}`)
        ]);

        const detallesData = await detallesResponse.json();
        const galeriaData = await galeriaResponse.json();

        setPiso(detallesData);
        setImagenes(galeriaData);
      } catch (error) {
        console.error('Error al obtener los detalles del piso y la galería:', error);
      }
    };

    fetchPisoAndImages();
  }, [id]);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  function stripHtml(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  }

  if (!piso) {
    return <p>Error: No se pudo cargar la información del piso.</p>;
  }
  
  if (images.length === 0) {
    return <p>Error: No se pudieron cargar las imágenes del piso.</p>;
  }

  const totalbanyos = parseInt(piso.aseos) + parseInt(piso.banos);

  
  

  return (
    <div>
      <section className="listings_details_main_image_box">
        <div className="container-full-width">
        <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            centerMode={true}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            centerSlidePercentage={window.innerWidth <= 768 ? 100 : 25}
          >
            {images.map((image, index) => (
              <div
                  key={index}
                  className="carousel-image"
                  onClick={() => openModal(image)}
                  style={{
                    marginRight: window.innerWidth > 768 ? '10px' : '0',
                    cursor: 'pointer'
                  }}
                >
      <img
        src={`http://fincat.es/imagenes/${image.imagen}`}
        onError={(e) => {
          e.target.src = `http://www.fincat.es/imagenes/min/${image.imagen}`;
          e.target.onerror = 'http://www.fincat.es/images/logo.jpg';   }}    
        alt={`Image ${index}`}
        style={{
          width: '100%',
          height: '300px',
          objectFit: 'cover'
        }}
      />
              </div>
            ))}
          </Carousel>
        </div>
      </section>
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Imagen ampliada"
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: '8px',
      outline: 'none',
      padding: '20px',
      width: '800px',     // Ancho absoluto en píxeles
      height: '600px',    // Altura absoluta en píxeles
      margin: 'auto',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
      position: 'relative'
    }
  }}
>

        {selectedImage && (
          <img
  src={`http://fincat.es/imagenes/min/${selectedImage.imagen}`}
  alt="Imagen Ampliada"
  style={{
    width: '100%',
    height: '100%',
    display: 'block',
    margin: '0 auto'
  }}
/>

        )}
        <button
          style={{
            backgroundColor: '#333',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 16px',
            fontSize: '14px',
            cursor: 'pointer',
            position: 'absolute',
            bottom: '20px',
            right: '20px'
          }}
          onClick={closeModal}
        >
          Cerrar
        </button>
      </Modal>
       <section className="listing_details_top">
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <div className="listing_details_top_left">
            <div className="listing_details_top_title">
              <h3>{piso.tipo} en {piso.operacion}</h3>
              <h4>{piso.preu}€</h4>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8">
          <div className="listing_details_top_right clearfix">
            <div className="listing_details_top_product_list_box">
              <ul className="listing_details_top_product_list list-unstyled">
                <li>
                  <div className="icon_box">

                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
  <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
</svg>

                  </div>
                  <div className="text_box">
                    <h5>{piso.habitaciones}</h5>
                    <p>Habitacions</p>
                  </div>
                </li>
                <li>
                  <div className="icon_box">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
  <path d="M96 77.3c0-7.3 5.9-13.3 13.3-13.3c3.5 0 6.9 1.4 9.4 3.9l14.9 14.9C130 91.8 128 101.7 128 112c0 19.9 7.2 38 19.2 52c-5.3 9.2-4 21.1 3.8 29c9.4 9.4 24.6 9.4 33.9 0L289 89c9.4-9.4 9.4-24.6 0-33.9c-7.9-7.9-19.8-9.1-29-3.8C246 39.2 227.9 32 208 32c-10.3 0-20.2 2-29.2 5.5L163.9 22.6C149.4 8.1 129.7 0 109.3 0C66.6 0 32 34.6 32 77.3V256c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H96V77.3zM32 352v16c0 28.4 12.4 54 32 71.6V480c0 17.7 14.3 32 32 32s32-14.3 32-32V464H384v16c0 17.7 14.3 32 32 32s32-14.3 32-32V439.6c19.6-17.6 32-43.1 32-71.6V352H32z" />
    
</svg>                  </div>
                  <div className="text_box">
                    <h5>{totalbanyos}</h5>
                    <p>Lavabos</p>
                  </div>
                </li>
                <li>
                  <div className="icon_box">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/></svg>                  </div>
                  <div className="text_box">
                    <h5>{piso.superficie_construida}</h5>
                    <p>m²</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="listing_details_top_right_btn_box">
              <a href="#" className="featured_btn">
                Destacat
              </a>
              <a href="#" className="sale_btn">
                En Venta
              </a>
              <p>
                Referència: <span>{piso.referencia}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Listing Details Top End*/}
  {/*Listing Details Bottom End*/}
  <section className="listing_details_bottom">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="listing_details_bottom_left">
            <div className="listing_details_bottom_left_content">
              <ul className="post_rating_and_view_list list-unstyled">
                <li>
                  <i className="far fa-clock" />
                  Pujat fa 2 dies
                </li>
                <li>
                  <ul className="rating_list list-unstyled">
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                    <li>
                      <i className="fas fa-star" />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Listing Details Top End*/}
  {/*Listing Details Start*/}
  <section className="listing_details">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="listing_details_left">
            <div className="listing_details_text">
              <p className="text_1">
              {stripHtml(piso.descripcio)}
              </p>

            </div>
            <div className="listings_details_features">
              <div className="listings_details_features_title">
                <h3>Informació</h3>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <ul className="listings_details_features_list list-unstyled">
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-bed" />
                      </div>
                      <div className="listings_details_content">
                        <p>Habitacions: {piso.habitaciones}</p>
                      </div>
                    </li>
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-pet-house" />
                      </div>
                      <div className="listings_details_content">
                        <p>Lavabos: {piso.aseos}</p>
                      </div>
                    </li>
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-shelves" />
                      </div>
                      <div className="listings_details_content">
                        <p>Banys: {piso.banos}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <ul className="listings_details_features_list list-unstyled">
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-fireplace" />
                      </div>
                      <div className="listings_details_content">
                        <p>Construit al <b>{piso.any_construccio}</b></p>
                      </div>
                    </li>
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-desk" />
                      </div>
                      <div className="listings_details_content">
                        <p>Superficie contruida: {piso.superficie_construida}</p>
                      </div>
                    </li>
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-staircase" />
                      </div>
                      <div className="listings_details_content">
                        <p>Superficie útil: {piso.superficie_util}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <ul className="listings_details_features_list list-unstyled">
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-window" />
                      </div>
                      <div className="listings_details_content">
                        <p>Amoblat:{piso.mobles}</p>
                      </div>
                    </li>
                    <li>
                      <div className="listings_details_icon">
                        <span className="icon-tiles" />
                      </div>
                      <div className="listings_details_content">
                        <p>Orientació:{piso.orientacio}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">

                <div className="listing_details_location">
                  <div className="listing_details_location_title">
                    <div className="left">
                      <h3>Contacte</h3>
                    </div>
                    <div className="right">
                    </div>
                  </div>
                  <ContactePis piso={piso}/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">

                <div className="listing_details_location">
                  <div className="listing_details_location_title">
                    <div className="left">
                      <h3>Localització</h3>
                    </div>
                    <div className="right">
                    </div>
                  </div>
                  <div className="listing_details_location_map">
                    <iframe
                      src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11863.36085627011!2d2.819486!3d41.9822403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1219afcd007b34a7!2sFinques%20Catalunya!5e0!3m2!1ses!2ses!4v1655724529727!5m2!1ses!2ses"'
                      className="google-map__contact"
                      allowFullScreen=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="listing_details_sidebar">
            <div className="listing_details_sidebar_agent">
              <div className="listing_details_sidebar_agent_img">
                <img
                  src="../../assets/images/resources/logo.jpg"
                  alt=""
                />
              </div>
              <div className="listing_details_sidebar_agent_content">
                <div className="listing_details_sidebar_agent_title">
                  <h3>Finques Catalunya</h3>
                  
                </div>
                <ul className="listing_details_sidebar_agent_contact_info list-unstyled">
                  <li>
                    <div className="icon">
                      <i className="fas fa-phone-square-alt" />
                    </div>
                    <div className="text">
                      <a href="tel:972 21 97 58">972 21 97 58</a>
                    </div>
                  </li>
                 

                  <li>
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="text">
                      <a href="mailto:needhelp@company.com">
                        info@finquescatalunya.com
                      </a>
                    </div>
                  </li>
                </ul>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Listing Details End*/}
  {/*Site Footer One Start*/}
  <footer
    className="site_footer"
    style={{ backgroundImage: "url(assets/images/footer.jpg)" }}
  >
    <div className="container">
      <div className="site_footer_one_top">
        <div className="row">
          <div className="col-xl-3 col-lg-6 wow fadeInUp" data-wow-delay="00ms">
            <div className="footer-widget__column footer_widget__about">
              <div className="footer_logo">
                <a href="index.html">
                  <img src="assets/images/resources/footer_logo.jpg" alt="" />
                </a>
              </div>
              <div className="footer_widget_about_text">
                <p>Finques Catalunya, treballant amb tú des de fa 35 anys</p>
              </div>
              <div className="footer_call_agent_box">
                <div className="icon">
                  <span className="icon-phone-call" />
                </div>
                <div className="text">
                  <p>Truca'ns</p>
                  <a href="tel:+34 972 219 758">972 219 758</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6 wow fadeInUp"
            data-wow-delay="200ms"
          >
            <div className="footer-widget__column footer_widget__explore clearfix">
              <div className="footer-widget__title">
                <h3>Explora</h3>
              </div>
              <ul className="footer_widget__explore_list list-unstyled">
                <li>
                  <a href="quisom.html">Qui som?</a>
                </li>
                <li>
                  <a href="#">Vendre</a>
                </li>
                <li>
                  <a href="contact.html">Contace</a>
                </li>
              </ul>
              <ul className="footer_widget__explore_list two list-unstyled">
                <li>
                  <a href="#">Política de privacitat</a>
                </li>
                <li>
                  <a href="#">Política de cookies</a>
                </li>
                <li>
                  <a href="#">Avís legal</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-8 wow fadeInUp"
            data-wow-delay="300ms"
          >
            <div className="footer-widget__column footer_widget__newsletter">
              <div className="footer-widget__title">
                <h3>Newsletter</h3>
              </div>
              <form action="#" className="footer_form">
                <div className="footer_input_box">
                  <input
                    type="email"
                    name="email"
                    placeholder="Correu Electrònic"
                  />
                  <button type="submit" className="button">
                    Envia
                  </button>
                </div>
              </form>
              <div className="footer_widget__newsletter_bottom">
                <p>Pl. Marquès de Camps, 17 17001 GIRONA</p>
                <a href="mailto:info@finquescatalunya.com">
                  info@finquescatalunya.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/*Site Footer One End*/}
  {/*Site Footer Bottom Start*/}
  <div className="site_footer_bottom">
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="site_footer_inner">
            <div className="site_footer_left">
              <p>
                © Copyright 2022 by <a href="#">Factoria81</a>
              </p>
            </div>
            <div className="site_footer__social">
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
              <a href="#">
                <i className="fab fa-facebook-square" />
              </a>
              <a href="#">
                <i className="fab fa-dribbble" />
              </a>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Site Footer Bottom End*/}
  {/* /.page-wrapper */}
  <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
    <i className="fa fa-angle-up" />
  </a>
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay side-menu__toggler mobile-nav__toggler" />
    <div className="mobile-nav__content">
      <span className="mobile-nav__close side-menu__toggler mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="index.html" aria-label="logo image">
          <img src="assets/images/resources/logo-2.png" alt="" />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container clearfix" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="icon-message" />
          <a href="mailto:needhelp@polimark.com">needhelp@tolins.com</a>
        </li>
        <li>
          <i className="icon-phone-call" />
          <a href="tel:666-888-0000">666 888 0000</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" aria-label="twitter">
            <i className="fab fa-twitter" />
          </a>
          <a href="#" aria-label="facebook">
            <i className="fab fa-facebook-square" />
          </a>
          <a href="#" aria-label="pinterest">
            <i className="fab fa-pinterest-p" />
          </a>
          <a href="#" aria-label="instagram">
            <i className="fab fa-instagram" />
          </a>
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
  </div>
  <div className="search-popup">
    <div className="search-popup__overlay custom-cursor__overlay">
      <div className="cursor" />
      <div className="cursor-follower" />
    </div>
    {/* /.search-popup__overlay */}
    <div className="search-popup__inner">
      <form action="#" className="search-popup__form">
        <input
          type="text"
          name="search"
          placeholder="Type here to Search...."
        />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  </div>

    </div>
  );
};

export default DetallePiso;
