import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Encabezado.css'; // Importa tus estilos personalizados

const Encabezado = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="main-nav__header-one two three">
      <div className="container">
        <nav className="header-navigation one stricky">
          <div className="container-box clearfix">
            <div className="main_nav_header_two_logo float-left clearfix">
              <div className="main_nav_header_two_logo_box">
                <a href="/">
                  <img src="assets/images/resources/logo.jpg" alt="" />
                </a>
              </div>
            </div>
            <div className="main-nav__main-navigation two clearfix float-left">
                  <ul className="main-nav__navigation-box float-left">
                    <li>
                      <Link className="nav-link" to="/">
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/FiltrePage">
                        Propietats
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/obranova">
                        Obra Nova
                      </Link>
                    </li>
                    <li>
                    <Link className="nav-link" to="/quisom">
                        Qui Som
                      </Link>                    
                      </li>
                    <li>
                    <Link className="nav-link" to="/contacte">
                      Contacte                      
                    </Link>                     
                    </li>
                  </ul>
                </div>
            <div className="container-box_two float-right clearfix ml-5">
              <div className="main-nav__left main-nav__left_one">
                <a
                  href="#"
                  className={`side-menu__toggler ${menuOpen ? 'active' : ''}`}
                  onClick={toggleMenu}
                >
                  <i className="fa fa-bars"></i>
                  
                </a>
                
                <div
                  className={`menu-dropdown ${menuOpen ? 'show' : ''}`}
                >
                  <ul>
                    <li>
                      <Link className="nav-link" to="/" onClick={closeMenu}>
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/FiltrePage" onClick={closeMenu}>
                        Propietats
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/quisom" onClick={closeMenu}>
                        Qui Som
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/contacte" onClick={closeMenu}>
                        Contacte
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Encabezado;
