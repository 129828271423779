import React, { useState, useEffect } from 'react';
import PisoCard from './PisoCard';
import PisDestecat from './PisDestecat';
import { useNavigate } from 'react-router-dom';
import ContactePis from './contactePis';
import UltimsPisos from './UltimsPisos';


const Buscador = () => {
  const navigate = useNavigate();
  const [busqueda, setBusqueda] = useState('');
  const [resultados, setResultados] = useState([]);
  const [mostrarResultados, setMostrarResultados] = useState(false);
  const [resultadosTotales, setResultadosTotales] = useState(0);
  const [resultadosVisibles, setResultadosVisibles] = useState([]);
  const [resultadosCargados, setResultadosCargados] = useState(0);
  

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const cargarMasResultados = () => {
    const nuevosResultados = resultados.slice(resultadosCargados, resultadosCargados + 4);
    setResultadosVisibles(prevResultados => [...prevResultados, ...nuevosResultados]);
    setResultadosCargados(resultadosCargados + 4);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 100) {
        cargarMasResultados();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`http://fincat.es/api/buscar_pisos.php?busqueda=${encodeURIComponent(busqueda)}`)
      .then((response) => response.json())
      .then((data) => {
        const resultados = data;
        navigate('/resultatsBusqueda', { state: { resultados } });
      })
      .catch((error) => {
        console.error('Error al buscar pisos:', error);
      });
  };

  return (
    <div>
<section className="">
      <div style={{ backgroundImage: `url("http://fincat.es/maqueta/assets/images/main-slider/slider-1-2.jpg")`, paddingTop: '100px',paddingBottom: '100px',height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',   backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', opacity: 0.8,}}>
        <div className="container">
    <div className="top-title">
      <div className="banner_two_shape"></div>
      <h1 style={{ color: 'white', textShadow: 'black 0.1em 0.1em 0.2em' }}>Finques Catalunya</h1>
      <h4 style={{ color: 'white', textShadow: 'black 0.1em 0.1em 0.2em' }}>La teva llar és vida. Busques on viure? Troba la casa dels teus somnis a Finques Catalunya, més de 350 immobles i servei personalitzat amb els nostres profesionals</h4>
      <br /><br /><br /><br /><br /><br />
    </div>
    <div className="product-tab-box tabs-box">
      <ul className="tab-btns tab-buttons clearfix list-unstyled">
        <li data-tab="#desc" className="tab-btn active-btn"><span>Cerca</span></li>
      </ul>

      <div className="tabs-content">
        <div className="tab active-tab" id="desc">
        {!mostrarResultados ? (
          <form className="banner_one_search_form" onSubmit={handleSubmit}>
            <div className="banner_one_search_form_input_box">
              <input type="text" placeholder="Introdueix la referència..." name="ref" id="ref" value={busqueda} onChange={handleBusquedaChange}/>
              <button type="submit" className="thm-btn banner_one_search_btn">Buscar propietat</button>
              <div className="banner_one_search_icon">
                <a href="#"><span className="icon-magnifying-glass"></span></a>
              </div>
            </div>
            <br />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>


          </form>
          ) : (
            <div className="row row-cols-5">
              {resultadosVisibles.map((piso) => (
                <div className="col mb-4" key={piso.id}>
                  <PisoCard piso={piso}/>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
    <section class="latest_properties">
                    <div class="container">
                        <div class="block-title text-center">
                            <h2>Propietats destacades</h2>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="thm-swiper__slider swiper-container thm-swiper__slider-pause-hover"
                                    data-swiper-options='{"spaceBetween": 100, "slidesPerView": 4, "autoplay": { "delay": 5000 }, "pagination": {
                                        "el": "#latest_properties_pagination",
                                        "type": "bullets",
                                        "clickable": true
                                    },
                                    "navigation": {
                                        "nextEl": ".latest_properties_next",
                                        "prevEl": ".latest_properties_prev",
                                        "clickable": true
                                    },
                                    "breakpoints": {
                                        "0": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "425": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "575": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "767": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 2
                                        },
                                        "991": {
                                            "spaceBetween": 20,
                                            "slidesPerView": 2
                                        },
                                        "1289": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 3
                                        },
                                        "1440": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 3
                                        }
                                    }}'>
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                          <PisDestecat pisos={resultados} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination" id="latest_properties_pagination"></div>
                    </div>
                </section>
            <section className="why_choose_one jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
  style={{ backgroundImage: "url(assets/images/backgrounds/girona.jpg)",width: "100vw"}}>
  <div className="container">
    <div className="why_choose_one_title">
      <h2>Perquè escollir-nos</h2>
    </div>
    <div className="why_choose_one_shape_one"
      style={{ backgroundImage: "url(assets/images/shapes/why_choose_one_shape_1.png)" }}></div>
    <div className="row">
      <div className="col-xl-3 col-lg-6 col-md-6">
        <div className="why_choose_one_single wow fadeInUp">
          <div className="why_choose_one_icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="4em" viewBox="0 0 384 512"><path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z"/></svg>           </div>
          <h3>Professionals<br />del sector</h3>
          <p>Més de 1000<br />propietats venudes.</p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6">
        <div className="why_choose_one_single wow fadeInUp" data-wow-delay="100ms">
          <div className="why_choose_one_icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="4em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 1 224 0a128 128 0 1 1 0 256zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 33.4 123.9 36-146.9c2-8.1 9.8-13.4 17.9-11.3c70.1 17.6 121.9 81 121.9 156.4c0 17-13.8 30.7-30.7 30.7H285.5c-2.1 0-4-.4-5.8-1.1l.3 1.1H168l.3-1.1c-1.8 .7-3.8 1.1-5.8 1.1H30.7C13.8 512 0 498.2 0 481.3c0-75.5 51.9-138.9 121.9-156.4c8.1-2 15.9 3.3 17.9 11.3l36 146.9 33.4-123.9z"/></svg>          </div>
          <h3>Agents <br /> experimentats</h3>
          <p>Tenim més de 35 anys<br />d'experiència en el sector.</p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6">
        <div className="why_choose_one_single wow fadeInUp" data-wow-delay="200ms">
          <div className="why_choose_one_icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="4em" viewBox="0 0 448 512"><path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"/></svg>          </div>
          <h3>Ven el teu<br />immoble amb nosaltres</h3>
          <p>Tenim una gran<br />agenda de clients.</p>
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6">
        <div className="why_choose_one_single wow fadeInUp" data-wow-delay="300ms">
          <div className="why_choose_one_icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="4em" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>          </div>
          <h3>Ens encarreguem <br /> de tot</h3>
          <p>T'oferim el millor servei<br /> per a que no hagis de<br /> preocupar-te de res.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="latest_properties">
                    <div class="container">
                        <div class="block-title text-center">
                            <h2>Últimes propietats</h2>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="thm-swiper__slider swiper-container thm-swiper__slider-pause-hover"
                                    data-swiper-options='{"spaceBetween": 100, "slidesPerView": 4, "autoplay": { "delay": 5000 }, "pagination": {
                                        "el": "#latest_properties_pagination",
                                        "type": "bullets",
                                        "clickable": true
                                    },
                                    "navigation": {
                                        "nextEl": ".latest_properties_next",
                                        "prevEl": ".latest_properties_prev",
                                        "clickable": true
                                    },
                                    "breakpoints": {
                                        "0": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "425": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "575": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 1
                                        },
                                        "767": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 2
                                        },
                                        "991": {
                                            "spaceBetween": 20,
                                            "slidesPerView": 2
                                        },
                                        "1289": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 3
                                        },
                                        "1440": {
                                            "spaceBetween": 30,
                                            "slidesPerView": 3
                                        }
                                    }}'>
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                          <UltimsPisos pisos={resultados} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination" id="latest_properties_pagination"></div>
                    </div>
                </section>
<footer className="text-center text-lg-start bg-white text-muted">


  <section className="">
  <div className="container text-center text-md-start mt-5">
    <div className="row mt-3">
      <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
        <h6 className="text-uppercase fw-bold mb-4"><img src="assets/images/resources/logo.jpg" alt="" /></h6>
      </div>
      <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        <p><i className="fas fa-home me-3 text-secondary"></i>Plaça del Marquès de Camps, 17, 17001 Girona</p>
        <p><i className="fas fa-envelope me-3 text-secondary"></i>info@example.com</p>
        <p><i className="fas fa-phone me-3 text-secondary"></i>972 21 97 58</p>
      </div>
    </div>
  </div>
</section>
<div class="mobile-nav__wrapper">
        <div class="mobile-nav__overlay side-menu__toggler mobile-nav__toggler"></div>
        <div class="mobile-nav__content">
            <span class="mobile-nav__close side-menu__toggler mobile-nav__toggler">
                <i class="fa fa-times"></i>
            </span>
            <div class="logo-box">
                <a href="index.html" aria-label="logo image">
                    <img src="assets/images/resources/logo.jpg" alt="" />
                </a>
            </div>
            <div class="mobile-nav__container clearfix"></div>
            <ul class="mobile-nav__contact list-unstyled">
                <li>
                    <i class="icon-message"></i>
                    <a href="mailto:needhelp@polimark.com">info@finquescatalunya.com</a>
                </li>
                <li>
                    <i class="icon-phone-call"></i>
                    <a href="tel:+34 972 219 758">972 219 758</a>
                </li>
            </ul>
            <div class="mobile-nav__top">
                <div class="mobile-nav__social">
                    <a href="#" aria-label="twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" aria-label="facebook"><i class="fab fa-facebook-square"></i></a>
                    <a href="#" aria-label="pinterest"><i class="fab fa-pinterest-p"></i></a>
                    <a href="#" aria-label="instagram"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
        </div>
    </div>


  <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.025)' }}>
    <img src="fetperfactoria81_gris1.png" alt="fetperfactoria81" />
  </div>

</footer>
</div>
    );
  };

export default Buscador;