import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContacteBuscador = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('http://fincat.es/api/sendemail.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.text();
        setResponseMessage(data);
      } else {
        setResponseMessage('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setResponseMessage('Error en la solicitud');
    }
  };
  return (
<div>
  <section className="contact">
    <div className="container">
      <div className="row mt-5">
        <div className="col-xl-4 col-lg-4">
          <div className="block-title text-left">
            <h4>Contacta'ns</h4>
            <h2>Sembla que no heu trobat el que buscàveu. Contacte'ns!</h2>
          </div>
          <div className="contact_text">
            <p>
              Recorda també que pots trucar-nos al 972 219 758, enviar-nos un
              correu al info@finquescatalunya.com o visita les nostres xarxes
              socials
            </p>
          </div>

        </div>
        <div className="col-xl-8 col-lg-8">
          <form onSubmit={handleSubmit} className="contact__form">
            <div className="row">
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="text" placeholder="Nom" name="name" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="email" placeholder="Correu" name="email" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="comment_input_box">
                  <input type="text" placeholder="Telefon" name="phone" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="comment_input_box">
                  <textarea
                    name="message"
                    placeholder="Escriu el teu missatge"
                    defaultValue={""}
                  />
                </div>
                <button type="submit" className="thm-btn comment-form__btn">
                  Enviar
                </button>
                <p>{responseMessage}</p>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  {/*Contact End*/}
  {/*Google Map*/}
  <section className="google_map">
    <div className="map-responsive">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11863.36085627011!2d2.819486!3d41.9822403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1219afcd007b34a7!2sFinques%20Catalunya!5e0!3m2!1ses!2ses!4v1655724529727!5m2!1ses!2ses"
        width={600}
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
      />
    </div>
  </section>
</div>
  );
};

export default ContacteBuscador;