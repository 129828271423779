import React, { useState, useEffect } from 'react';
import PisoCard from './PisoCard';

const ObraNova = () => {
  const [pisos, setPisos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://fincat.es/api/obranova.php');
        const data = await response.json();
        setPisos(data);
      } catch (error) {
        console.error('Error al hacer la petición:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="row">
        {pisos.map((piso) => (
          <div className="col-md-3" key={piso.id}>
            <PisoCard piso={piso} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ObraNova;
