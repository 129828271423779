import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Encabezado from './components/Encabezado';
import PaginaInicio from './components/Inici';
import FiltrePage from './pages/FiltrePage';
import Filtre from './components/Filtre';
import 'bootstrap/dist/css/bootstrap.min.css';
import DetallePiso from './components/DetallePiso';
import QuiSom from './components/quisom';
import Contacte from './components/contacte';
import Resultados from './components/ResultatsBusqueda';
import ObraNova from './components/ObraNova';


const App = () => {
  return (
    <Router>
      <Encabezado />
      <Routes>
        <Route path="/" element={<PaginaInicio />} />
        <Route path="/FiltrePage" element={<Filtre />} />
        <Route path="/obranova" element={<ObraNova />} />
        <Route path="/ficha/:referencia" element={<DetallePiso />} />
        <Route path="/quisom" element={<QuiSom />} /> 
        <Route path="/contacte" element={<Contacte />} /> 
        <Route path="/resultatsBusqueda" element={<Resultados/>}/> 
      </Routes>
    </Router>
  );
};

export default App;
