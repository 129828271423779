import React from 'react';
import { Link } from 'react-router-dom';

const PisoCard = ({ piso }) => {
  const totalbanyos = parseInt(piso.aseos) + parseInt(piso.banos);


  return (
<div className='col-12'>
<div className="latest_properties_img" >
<img
  src={`http://fincat.es/imagenes/${piso.imagen}`}
  onError={(e) => {
    e.target.src = `http://www.fincat.es/imagenes/min/${piso.imagen}`
    e.target.onerror ="http://www.fincat.es/images/logo.jpg";
  }}        alt=""
        style={{ width: '100%', height: '200px',objectFit: 'cover'}}
      />    <div className="latest_properties_icon">
    </div>
    <div className="featured_and_sale_btn">
    <Link to={`/ficha/${piso.referencia}`} className="sale_btn">
            Veure fitxa
          </Link>
    </div>
  </div>
  <div className="latest_properties_content">
    <div className="latest_properties_top_content">
      <h4>
      <Link to={`/ficha/${piso.referencia}`} className="sale_btn">
            {piso.tipo} en {piso.operacion} {piso.poblacio}
          </Link>
      </h4>
      <h3>{parseFloat(piso.preu).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}</h3>
    </div>
    <div className="latest_properties_bottom_content">
      <ul className="list-unstyled">
        <li>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
  <path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
</svg> {piso.habitaciones}
        </li>
        <li>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
  <path d="M96 77.3c0-7.3 5.9-13.3 13.3-13.3c3.5 0 6.9 1.4 9.4 3.9l14.9 14.9C130 91.8 128 101.7 128 112c0 19.9 7.2 38 19.2 52c-5.3 9.2-4 21.1 3.8 29c9.4 9.4 24.6 9.4 33.9 0L289 89c9.4-9.4 9.4-24.6 0-33.9c-7.9-7.9-19.8-9.1-29-3.8C246 39.2 227.9 32 208 32c-10.3 0-20.2 2-29.2 5.5L163.9 22.6C149.4 8.1 129.7 0 109.3 0C66.6 0 32 34.6 32 77.3V256c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H96V77.3zM32 352v16c0 28.4 12.4 54 32 71.6V480c0 17.7 14.3 32 32 32s32-14.3 32-32V464H384v16c0 17.7 14.3 32 32 32s32-14.3 32-32V439.6c19.6-17.6 32-43.1 32-71.6V352H32z" />
    
</svg>{totalbanyos}
        </li>
        <li>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/></svg>          
        {piso.superficie_construida} m²
        </li>
      </ul>
    </div>
  </div>
</div>




  );
};

export default PisoCard;
