import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactePis = ({piso}) => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('http://fincat.es/api/sendemail_pis.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.text();
        setResponseMessage(data);
      } else {
        setResponseMessage('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setResponseMessage('Error en la solicitud');
    }
  };
  return (
<div>
        <div className="col-xl-12 col-lg-12">
          <form onSubmit={handleSubmit} className="contact__form">
            <div className="row">
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="text" placeholder="Nom" name="name" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="email" placeholder="Correu" name="email" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="text" placeholder="Telefon" name="phone" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="comment_input_box">
                  <input type="text" placeholder={`Referencia del piso: ${piso.referencia}`} defaultValue={piso.referencia} name="referencia" readOnly/>
                </div>
              </div>
            </div>
            
            
            <div className="row">
              <div className="col-xl-12">
                <div className="comment_input_box">
                  <textarea
                    name="message"
                    placeholder={`Missatge`} 
                    />
                </div>
                <button type="submit" className="thm-btn comment-form__btn">
                  Enviar
                </button>
                <p>{responseMessage}</p>

              </div>
            </div>
          </form>
        </div>
      </div>
  );
};

export default ContactePis;