import React from 'react';
import Encabezado from './Encabezado';
import Buscador from './Buscador';
import CarruselPisosDestacados from './PisDestecat'
import 'bootstrap/dist/css/bootstrap.min.css';
import MapaConPuntos from './mapa';

const PaginaInicio = () => {
  return (
    <div>
        <Buscador />
    </div>
  );
};

export default PaginaInicio;
