import React, { useState, useEffect } from 'react';
import PisoCard from './PisoCard';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DetallePiso from './DetallePiso';
import MapaConPuntos from './mapa';
const Filtre = () => {
  const [precioMinimo, setPrecioMinimo] = useState('');
  const [precioMaximo, setPrecioMaximo] = useState('');
  const [superficieMinima, setsuperficieMinima] = useState('');
  const [superficieMaxima, setsuperficieMaxima] = useState('');
  const [habitaciones, setHabitaciones] = useState('');
  const [banos, setBanos] = useState('');
  const [poblaciones, setPoblaciones] = useState([]);
  const [tipus, setTipus] = useState([]);
  const [selectedTipus, setSelectedTipus] = useState([]);
  const [selectedPoblacion, setSelectedPoblacion] = useState('');

  const [zonas, setZonas] = useState([]);
  const [selectedZona, setSelectedZona] = useState('');

  const [subzonas, setSubzonas] = useState([]);
  const [selectedSubzona, setSelectedSubzona] = useState('');

  const [resultados, setResultados] = useState([]);
  const [sinResultados, setSinResultados] = useState(false);
  const [operacio, setOperacio] = useState('');
  const [selectedOperacio, setSelectedOperacio] = useState('');
  const [errorMensaje, setErrorMensaje] = useState('');

  const buscarPisos = () => {
    limpiarResultados();
    const url = `http://fincat.es/api/filtre.php?precioMinimo=${encodeURIComponent(
      precioMinimo
    )}&precioMaximo=${encodeURIComponent(
      precioMaximo
    )}&habitaciones=${encodeURIComponent(
      habitaciones
    )}&banos=${encodeURIComponent(
      banos
    )}&poblacion=${encodeURIComponent(selectedPoblacion)}&operacion=${encodeURIComponent(selectedOperacio)}&zona=${encodeURIComponent(selectedZona)}&subzona=${encodeURIComponent(selectedSubzona)}&tipus=${encodeURIComponent(selectedTipus)}&superficieMinima=${encodeURIComponent(superficieMinima)}&superficieMaxima=${encodeURIComponent(superficieMaxima)}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          setSinResultados(true);
        } else {
          setResultados(data);
        }
      })
      .catch((error) => {
        console.error('Error al buscar pisos:', error);
      });
  };

  useEffect(() => {
    obtenerPoblaciones();
    obtenerZona();
    obtenerSubzona();


    obtenerTipus();
  }, []);

  const obtenerPoblaciones = () => {
    fetch('http://fincat.es/api/poblacions.php')
      .then((response) => response.json())
      .then((data) => {
        setPoblaciones(data);
      })
      .catch((error) => {
        console.error('Error al obtener poblaciones:', error);
      });
  };

  const obtenerTipus = () => {
    fetch('http://fincat.es/api/tipus_immoble.php')
      .then((response) => response.json())
      .then((data) => {
        setTipus(data);
      })
      .catch((error) => {
        console.error('Error al obtener tipos:', error);
      });
  };

  const obtenerZona = () => {
    fetch('http://fincat.es/api/zona_poblacions.php')
      .then((response) => response.json())
      .then((data) => {
        setZonas(data);
      })
      .catch((error) => {
        console.error('Error al obtener poblaciones:', error);
      });
  };

  const obtenerSubzona = () => {
    fetch('http://fincat.es/api/subzona_poblacions.php')
      .then((response) => response.json())
      .then((data) => {
        setSubzonas(data);
      })
      .catch((error) => {
        console.error('Error al obtener poblaciones:', error);
      });
  };


  const handlePrecioMinimoChange = (event) => {
    setPrecioMinimo(event.target.value);
  };

  const handlePrecioMaximoChange = (event) => {
    setPrecioMaximo(event.target.value);
  };

  const handleSuperficieMinimaChange = (event) => {
    setsuperficieMinima(event.target.value);
  };

  const handleSuperficieMaximaChange = (event) => {
    setsuperficieMaxima(event.target.value);
  };

  const handleHabitacionesChange = (event) => {
    setHabitaciones(event.target.value);
  };

  const handleBanosChange = (event) => {
    setBanos(event.target.value);
  };

  const handleZonaChange = (event) => {
    setSelectedZona(event.target.value);
  };

  const handlePoblacionChange = (event) => {
    setSelectedPoblacion(event.target.value);
  };

  const handleSubzonaChange = (event) => {
    setSelectedSubzona(event.target.value);
  };

  const handleTipusChange = (event) => {
    setSelectedTipus(event.target.value);
  };

  const handleOperacio = (event) =>{
    setSelectedOperacio(event.target.value)
  }

  const limpiarResultados = () => {
    setResultados([]);
    setSinResultados(false);
  };



  return (
    <div className='filtre-pisos' >
      <section className="listing_one_wrap two">
  <div className="container-full-width clearfix">
    <div className="listing_one_content_right">
      <form className="listing_one_content_right_form">
        <div className="row">
        <div className="col-xl-3">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Venta</h4>
              <select value={selectedOperacio} onChange={handleOperacio} className="form-select">
                <option value="">Seleccioneu</option>
                <option>venta</option>
              </select>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Escull tipus d'immoble</h4>
              <select value={selectedTipus} onChange={handleTipusChange} className="form-select">
              <option value="">Seleccioneu</option>
              {tipus.map((tipu) => (
                <option key={tipu} value={tipu}>
                  {tipu}
                </option>
              ))}
            </select>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Escull població</h4>
              <select value={selectedPoblacion} onChange={handlePoblacionChange} className="form-select">
              <option value="">Seleccioneu</option>
              {poblaciones.map((poblacion) => (
                <option key={poblacion} value={poblacion}>
                  {poblacion}
                </option>
              ))}
            </select>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Zona</h4>
              <select value={selectedZona} onChange={handleZonaChange} className="form-select">
              <option value="">Seleccioneu</option>
              {zonas.map((zona) => (
                <option key={zona} value={zona}>
                  {zona}
                </option>
              ))}
            </select>
            </div>
          </div>

  



          
          
        </div>
      </form>
<div className="listing_one_quantity_and_price-ranger">
  <div className="row">
    
  <div className="col-xl-3">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Superfície</h4>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Min" aria-label="Min" value={superficieMinima} onChange={handleSuperficieMinimaChange}/>
                <input type="text" class="form-control" placeholder="Max" aria-label="Max" value={superficieMaxima} onChange={handleSuperficieMaximaChange}/>
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Preu</h4>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Min" aria-label="Min" value={precioMinimo} onChange={handlePrecioMinimoChange}/>
                <input type="text" class="form-control" placeholder="Max" aria-label="Max" value={precioMaximo} onChange={handlePrecioMaximoChange}/>
              </div>
            </div>
    </div>
  <div className="col-xl-2">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Habitacions</h4>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Min" aria-label="Min" value={habitaciones} onChange={handleHabitacionesChange}/>
              </div>
            </div>
    </div>
    <div className="col-xl-2">
            <div className="listing_one_content_right_input_box">
              <h4 className="title">Lavabos</h4>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Min" aria-label="Min" value={banos} onChange={handleBanosChange}/>
              </div>
            </div>
    </div>

</div>

<div class="filter_by_tags_btn">
                                <a class="filter_results" onClick={buscarPisos} style={{color: "white"}}>Filtrar resultats</a>
                            </div>

</div>

  <div className="filtre-pisos">
        <h3>Resultados de la búsqueda:</h3>
        {errorMensaje && <p className="error-message">{errorMensaje}</p>}
        {sinResultados ? (
          <p>No s'han trobat resultats.</p>

        ) : (
          <div className="pisos-grid">
            <Routes>
              <Route path="/ficha/:referencia" element={<DetallePiso />} />
              <Route path="/" element={resultados.map((piso) => <PisoCard key={piso.id} piso={piso} />)} />
            </Routes>
          </div>
        )}
      </div>
    </div>
  </div>
  <div className="listing__one__map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11863.36085627011!2d2.819486!3d41.9822403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1219afcd007b34a7!2sFinques%20Catalunya!5e0!3m2!1ses!2ses!4v1655724529727!5m2!1ses!2ses"
    className="google-map"
    allowFullScreen=""
  />
</div>

</section>
      <style jsx>{`
        .filtre-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 20px;
        }

        .filtre-filtros-container {
          height: calc(100vh - 100px); /* Ajusta la altura máxima según tus necesidades */
          overflow-y: auto;
          width: 300px;
          background-color: #f2f2f2;
          padding: 20px;
          border-radius: 8px;
          margin-top: 20px; /* Agregado: margen superior para separar del encabezado */
        }

        .filtre-filtros {
          margin-bottom: 20px;
        }

        .filtre-input {
          margin-bottom: 10px;
        }

        .filtre-input label {
          display: block;
          margin-bottom: 5px;
        }

        .filtre-input input,
        .filtre-input select {
          width: 100%;
          padding: 8px;
          border-radius: 4px;
          border: 1px solid #ccc;
        }

        .filtre-input select {
          appearance: none;
          background-color: #fff;
          cursor: pointer;
        }

        .filtre-pisos {
          flex-grow: 1;
          margin-left: 20px;
        }

        .pisos-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr); /* Cambia el número 4 según tu preferencia */
          grid-gap: 20px;
        }
        
      `}</style>
    </div>
  );
};

export default Filtre;