import React from 'react';
import { useLocation } from 'react-router-dom';
import PisoCard from './PisoCard';
import ContacteBuscador from './contacteBuscador';

const Resultados = () => {
  const location = useLocation();
  const resultados = location.state?.resultados || [];

  return (
    <div style={{marginLeft: "10%", marginRight:"10%"}}>
      {resultados.length > 0 && <h2>Resultados de búsqueda:</h2>}
      {resultados.length > 0 ? (
        <div className="row">
          {resultados.map((resultado) => (
            <div className="col-md-3" key={resultado.id}>
              <PisoCard piso={resultado} />
              <p>{resultado.nombre}</p>
            </div>
          ))}
        </div>
      ) : (
        <ContacteBuscador/>
        )}
    </div>
  );
};

export default Resultados;
